import React, { useEffect, useRef, useState } from 'react'
import logo from '..//assets/logo.png';
import newLogo from "../assets/newLogo.png"
import ios from '../assets/ios.png'
import android from '..//assets/android.png'
import dummyVid from "../assets/dummyVid.mp4"
import awsm1 from '../assets/swipe.png'
import awsm2 from '../assets/safety.png'
import awsm3 from '../assets/match.png'
import mobile from '../assets/mobile.png'
import { MenuOutlined } from '@ant-design/icons';
import './Landing.css'
import { Button, Col, Drawer, Form, Image, Input, QRCode, Row, Typography } from 'antd';
import { Link } from 'react-scroll';
import solidAppMaker from "../assets/solidappmakerLogo.png"
import whyTopLogo from "../assets/whyTopLogo.png"
import contactSide from "../assets/table.png"
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PauseOutlined } from '@ant-design/icons';
import playIcon from "../assets/playIcon.png"

const Landing = () => {
    const [form] = Form.useForm()
    const [btnLoading, setBtnLoading] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    const handlePlayPause = () => {
        const video = videoRef.current;
        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        setIsPlaying(!isPlaying);
    };
    const onSubmitForm = async (values) => {
        console.log('Success:', values)
        setBtnLoading(true)
        try {
            let res = await axios.post(`https://dev.swipeleft.com/api/v1/common/help_and_support`, {
                name: values?.name,
                email: values?.email,
                message: values?.message
            },
                {
                    'Content-Type': 'application/json',

                }
            )
            if (res?.status) {
                form.setFieldsValue({
                    name: '',
                    email: '',
                    message: ''
                })
                setBtnLoading(false)
                toast.success(res?.data?.message)
            } else {
                setBtnLoading(false)
                toast.error(res?.data?.message)
            }
        } catch (err) {
            console.log("errr", err);
            toast.error(err?.message)
            setBtnLoading(false)
        }
    };
    const [open, setOpen] = useState(false);
    return (
        <>
            {/* HEADER */}
            <ToastContainer />
            <div className='head_container'>
                <Row className='common_container header__div'>
                    <Col className='heder_space' md={24} lg={4} xs={24} sm={24}>
                        {/* <img className='logo' src={logo} alt='logo' /> */}
                        <img className='logo' src={newLogo} alt='logo' />
                        <div className='showMenu_btn' onClick={() => setOpen(true)} >
                            <MenuOutlined style={{ fontSize: '1.5rem' }} />
                        </div>
                    </Col>
                    <Col md={24} lg={16} xs={24} sm={24} className={'heading_detail'} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Link className="heading" activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="Home" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Home</Link>
                        <Link className="heading" activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-80}>Features</Link>
                        <Link className="heading" activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="About" spy={true} smooth={true} delay={50} duration={50} offset={-70}>About us</Link>
                        <Link className="heading" activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Download
                        </Link>
                        <Link className="heading" activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Contact Us!
                        </Link>
                    </Col>
                    <Col md={24} lg={4} xs={24} sm={24} >
                        <div className='download_button' >
                            <a href="https://play.google.com/store/games" target='_blank'>
                                <img src={android} alt='logo' height={'100%'} />
                            </a>
                            <a href="https://www.apple.com/in/app-store/" target='_blank' >
                                <img src={ios} alt='logo' height={'100%'} />
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* SWAP IMAGE SECTION  */}
            <section id="Home">
                <div className=''>
                    <div className='background_img' />
                    <div className='swap_text mx-auto '>
                        <Typography className='editor_choice'>
                            <span style={{ fontWeight: 600, }}>#1</span><span> Editiors Choice App of 2023</span>
                        </Typography>
                        <h3 className='heaing_tag' style={{ marginTop: 25 }}>MAKE THE<span style={{ fontWeight: 1000, color: "#2653A2" }}> LEFT</span> MOVE</h3>
                        {/* <h6 className='heaing_tag' style={{ fontWeight: 400 }}>lifestyle</h6> */}
                        <p className='try_freeBtnTopText' style={{ color: "#000" }}>The Safest Way To Get What You Want</p>
                        <Button className='try_button' type="ghost">Try For Free</Button>
                    </div>
                </div>
            </section>

            {/* SECTION ABOUT US */}
            {/* <section id="About" className='aboutus_background aboutPadding'>
                <h1 className='title'>About Us</h1>
                <Typography className='about_content'>
                    Top Couture is an online retailer that specializes in printing artwork on articles like t-shirts, hoodies, and other apparel. We are passionate about providing our customers with high-quality, unique, and stylish clothing that expresses their individuality.
                </Typography>
                <Typography className='about_content'>
                    We believe that everyone should have the opportunity to express themselves through their fashion choices. That's why we offer a wide variety of artwork to choose from, including designs from independent artists, popular culture icons, and our own in-house designers.
                </Typography>
                <Typography className='about_content'>
                    We are also committed to providing our customers with the best possible shopping experience. We offer fast and free shipping on all orders, and our customer service team is always available to answer your questions and help you find the perfect items to express your style.
                </Typography>
                <Button className='try_button' style={{ marginTop: 60 }} type="ghost">Contact Us</Button>
            </section > */}

            {/* Awesome features */}
            <section className='awesome_mainDiv' id="Features">
                <div className='features_section'>
                    <p className='title'>Awesome features</p>
                    <div className="">
                        <p className='paragraph'>Elevate your dating journey with Compatibility Insights, connecting you with like-minded individuals based on shared interests. <br /> Ensure secure meet-ups using our Safety Check feature. Forge meaningful connections on our dynamic platform.</p>
                    </div>
                </div>

                <div className="common_container awsome_innner">
                    <div className="featuContentDiv">
                        <div className="feature_imgDiv">
                            <img className='features_image' src={awsm1} alt="image" />
                        </div>
                        <h6 style={{ marginTop: 18, color: '#2653A2', fontWeight: 600, fontSize: '1.3rem' }}>Swipe left</h6>
                        <Typography style={{ textAlign: 'center', fontSize: '1rem' }}>Swipe Left: Effortless connections. Find love with a simple swipe, discover shared interests, and spark meaningful conversations. Your perfect match awaits!</Typography>
                    </div>
                    <div className="featuContentDiv">
                        <div className="feature_imgDiv">
                            <img className='features_image' src={awsm2} alt="image" />
                        </div>
                        <h6 style={{ marginTop: 18, color: '#2653A2', fontWeight: 600, fontSize: '1.3rem' }}>safety</h6>
                        <Typography style={{ textAlign: 'center', fontSize: '1rem' }}>Swipe Secure: Prioritizing your safety. Our app includes robust safety features, ensuring secure connections and giving you peace of mind throughout your dating journey.</Typography>
                    </div>
                    <div className="featuContentDiv">
                        <div className="feature_imgDiv">
                            <img className='features_image' src={awsm3} alt="image" />
                        </div>
                        <h6 style={{ marginTop: 18, color: '#2653A2', fontWeight: 600, fontSize: '1.3rem' }}>Match</h6>
                        <Typography style={{ textAlign: 'center', fontSize: '1rem' }}>MatchUp: Explore genuine connections effortlessly. Our app intelligently matches you based on shared interests and values, paving the way for meaningful conversations and potential long-lasting relationships.</Typography>
                    </div>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <Button className='try_button' type="primary">Contact Us</Button>
                </div>
            </section >


            {/*video*/}
            {/* <section className='common_container mt-5'>
                <div className="videoDiv">
                    <video className='video' ref={videoRef}>
                        <source src={dummyVid} type="video/mp4" />
                    </video>
                    {
                        isPlaying ?
                            <div className="pauseIcon" onClick={handlePlayPause}>
                                <PauseOutlined classID='pause_icon' />
                            </div>
                            :
                            <div className="playIcon" onClick={handlePlayPause}>
                                <img src={playIcon} alt="play" />
                            </div>
                    }
                    <div className="pause_playIcon" onClick={handlePlayPause}>
                        {isPlaying ? (
                            <PauseOutlined style={{ color: 'white', fontSize: "4.1rem", }} />
                        ) : (
                            <img src={playIcon} alt="play" width={'25%'} />
                        )}
                    </div>
                </div>
            </section > */}

            {/* why topCouture */}
            <div id="About" className='mt-5' style={{ padding: '50px 0px', backgroundColor: '#F9FAFC' }}>
                <Row gutter={[]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className='leftside_about common_container'>
                            <h1 className='title'>Why Swipe Left</h1>
                            <article style={{ lineHeight: "25px", color: '#4A4B4D', marginTop: 30 }} className=''>Swipe Left redefines the dating experience, placing emphasis on user-friendly interactions and genuine connections. Our app intuitively learns your preferences as you swipe, providing a tailored selection of potential matches. Dive deeper into shared interests and engage in thoughtful conversations. The more you explore, the more the app refines its suggestions, increasing the likelihood of discovering someone truly compatible. With Swipe Left, every swipe is an opportunity to create a meaningful connection, making your journey to find love enjoyable, authentic, and filled with exciting possibilities.</article>
                            <div className="center_btn">
                                <Button className='try_button' type="ghost">Download</Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="car_div">
                            <img className='rightside_player' src={whyTopLogo} alt='logo' />
                        </div>
                    </Col>
                </Row>
            </div>


            {/* downdld section */}
            <section className='common_container download_section' id="Download" >
                <div className="" style={{ textAlign: 'center' }}>
                    <h1 className='title'>Download the App Now</h1>
                    <Typography style={{ fontSize: '1rem' }} className='try_freeBtnTopText'>Download our app now to explore a world of unique artwork, customizable designs, and high-quality fashion at your fingertips.</Typography>
                    <div className="logosDIv">
                        <a href="https://play.google.com/store/games" target='_blank' style={{ textAlign: 'end' }}>
                            <img src={android} alt="play_store" className='playstre_img' />
                        </a>
                        <a href="https://www.apple.com/in/app-store/" target='_blank' style={{ textAlign: "start" }} >
                            <img src={ios} alt="ios_store" className='playstre_img' />
                        </a>
                    </div>
                    <div className="" style={{ marginTop: 50 }}>
                        <img src={mobile} alt='mobiless' className='mobiles' />
                    </div>
                </div>
            </section>


            {/* contact */}
            <section style={{ minHeight: '75vh', display: 'grid', placeItems: 'center' }} className='common_container contactDiv' id="Contact">
                <Row gutter={[30, 30]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className=''>
                        <div className="contact_Side" >
                            <img style={{ width: '70%', marginLeft: '10px' }} src={contactSide} alt='logo' />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className='contact_form'>
                        <div className="">
                            <p className='title'>Contact Us</p>
                            <p className='section_paragraph'>Reach out to us for any inquiries, assistance, or just to say hello! We're here to make your experience exceptional</p>
                            <div className="">
                                <Form name="form" form={form} onFinish={onSubmitForm} layout="vertical" className="modal_form" >
                                    <Form.Item name="name" rules={[
                                        {
                                            required: true,
                                            message: "Please enter name!",
                                        },
                                    ]} >
                                        <Input className='form_style' placeholder="Name*" />
                                    </Form.Item>
                                    <Form.Item name="email" rules={[
                                        {
                                            type: 'email',
                                            message: 'Please enter a valid email!',
                                        },
                                        {
                                            required: true,
                                            message: "Please enter email!",
                                        },
                                    ]}>
                                        <Input className='form_style' placeholder="E-mail*" />
                                    </Form.Item>
                                    <Form.Item name="message" rules={[
                                        {
                                            required: true,
                                            message: "Please enter message!",
                                        },
                                    ]}>
                                        <Input.TextArea rows={5} className='messageform_style' placeholder="Message*" />
                                    </Form.Item>
                                    <div className="center_btn">
                                        <Form.Item>
                                            <Button loading={btnLoading} type='ghost' className="try_button" htmlType='submit'>Send</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row >
            </section>

            {/* footer */}
            <section >
                <div className='footer_parent common_container'>
                    <div className="" style={{ textAlign: 'center' }}>
                        <img src={logo} alt="log" className='footer_logo' />
                    </div>
                    <div className="" style={{ marginTop: 30 }}>
                        <Row>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <div className="footer_detail">
                                    <Link activeStyle={{ color: '#2653A2' }}
                                        className='heading footer_link'
                                        to="Home" spy={true} smooth={true} delay={100} duration={100} style={{ fontWeight: 500 }}>Home</Link>
                                    <Link className="heading footer_link" activeStyle={{ color: '#2653A2' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-80} style={{ fontWeight: 500 }}>Features</Link>
                                    <Link to="About" spy={true} smooth={true} delay={50} duration={50} className='heading footer_link' activeStyle={{ color: '#2653A2' }} style={{ fontWeight: 500 }}>About us</Link>
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <div className='footer_detail'>
                                    <Link className="heading footer_link" activeStyle={{ color: '#2653A2' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-100} style={{ fontWeight: 500 }}>
                                        Download
                                    </Link>
                                    <Link className="heading footer_link" activeStyle={{ color: '#2653A2' }} to="Contact" spy={true} smooth={true} delay={50} duration={50} style={{ fontWeight: 500 }}>
                                        Contact Us!
                                    </Link>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <div className="qr_codeDiv">
                                    <QRCode value={'https://www.top-couture.com/'} />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <div className="footer_dwnld__btn">
                                    <a href="https://play.google.com/store/games" target='_blank'>
                                        <img src={android} alt='logo' />
                                    </a>
                                    <a href="https://www.apple.com/in/app-store/" target='_blank' >
                                        <img src={ios} alt='logo' />
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='bottom'>
                    <article > &copy; 2023-All Rights Reserved - Swipe Left | Designed & Developed by <a href="https://solidappmaker.com/" target='_blank' className='solid_link'><img src={solidAppMaker} alt="logo" className='solidApp_logo' /></a> </article>
                </div>
            </section>

            {/*   for md screen */}
            <Drawer placement="right" onClose={() => setOpen(false)} open={open}>
                <Link className="heading" style={{ marginLeft: 15 }} activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="Home" spy={true} smooth={true} delay={50} duration={50} offset={-70} onClick={() => setOpen(false)}>Home</Link>
                <Link className="heading" style={{ marginLeft: 15 }} activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="About" spy={true} smooth={true} delay={50} duration={50} offset={-70} onClick={() => setOpen(false)}>About us</Link>
                <Link className="heading" style={{ marginLeft: 15 }} activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-70} onClick={() => setOpen(false)}>Features</Link>
                <Link className="heading" style={{ marginLeft: 15 }} activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={200} onClick={() => setOpen(false)}>
                    Download
                </Link>
                <Link className="heading" style={{ marginLeft: 15 }} activeStyle={{ color: '#2653A2', fontSize: 16, fontWeight: 600, fontSize: 16 }} to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70} onClick={() => setOpen(false)}>
                    Contact Us
                </Link>
                <div className='download_butt'>
                    <div className='my-2' style={{ width: '50%' }}>
                        <a href="https://www.apple.com/in/app-store/" target='_blank' >
                            <Image height={40} preview={false} src={ios} alt='logo' />
                        </a>
                    </div>
                    <div style={{ width: '50%' }}>
                        <a href="https://play.google.com/store/games" target='_blank'>
                            <Image height={40} preview={false} src={android} alt='logo' />
                        </a>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Landing