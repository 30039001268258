import Landing from './Screen/Landing';

function App() {
  return (
    <div className="">
      <Landing />
    </div>
  );
}

export default App;
